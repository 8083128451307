import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { apis } from 'src/environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/student`

    constructor(public http: HttpClient) { }

    stripePay(params: any): Observable<any> {
        const url = `${this.baseUrl}/stripe/pay`

        return this.http.post<any>(url, params)
    }

}
