import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { flatMap } from 'lodash'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { DataService } from './data.service'

@Component({
    selector: 'app-stripe-payment-initiate',
    templateUrl: './stripe-payment-initiate.component.html',
    styleUrls: ['./stripe-payment-initiate.component.css']
})
export class StripePaymentInitiateComponent implements OnInit {
    id: any
    loadingStatus: boolean = false
    spinnerSVG = '/assets/images/rolling-main.svg'

    constructor(
        public api: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private ds: DataService,
        private alert: IAlertService
    ) {
        this.id = this.route.snapshot.params.id
        const params = { id: this.id }
        if (this.api.user?.token) {
            this.loadingStatus = true
            this.ds.stripePay(params).subscribe((resp: any) => {
                if (resp.success === false) {
                    this.alert.error(resp?.message || '')
                    this.loadingStatus = false
                    return
                } else {
                    this.loadingStatus = false
                    window.open(resp.data)
                }
            })
        } else {
            this.router.navigate(['/login'], { queryParams: { stripeUrlId: this.id }, replaceUrl: true })
        }
    }

    ngOnInit() { }
}
